import React from "react";
import Seo from "./../components/Seo";
import Contact from "./../components/Contact/Contact";

const LandingPage = () => {
    return (
        <>
            <Seo page="contact"/>
            <Contact />
        </>
    );
};
export default LandingPage;
